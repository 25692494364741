import React from 'react'
import styled from 'styled-components'
import { graphql, PageProps } from 'gatsby'

import media from 'styles/media'

import Layout from 'components/layout/layout'

import { PolitykaPrywatnosciPageQuery } from 'types/graphql'
import Seo from 'components/layout/seo'

const StyledHeader = styled.div`
  padding: 260px 20px;

  ${media.lg.min} {
    padding: 300px 20px 200px 20px;
  }
`

const StyledWrapper = styled.div`
  width: 100%;
  position: relative;
`

const StyledContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;

  & p {
    margin-bottom: 40px;
  }
`

const StyledTitle = styled.h1`
  font-size: 36px;
  text-align: center;

  ${media.xl.min} {
    font-size: 48px;
  }
`

const StyledCircles = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
`

const RedCircle = styled.div`
  display: block;
  position: absolute;
  width: 140px;
  height: 140px;
  top: 55%;
  right: -70px;
  border: 1px solid #dd001a;
  border-radius: 50%;
  z-index: 1;

  ${media.xl.min} {
    display: none;
  }
`

const GreenCircle = styled.div`
  position: absolute;
  height: 200px;
  width: 200px;
  border: 1px solid ${({ theme }) => theme.colors.green};
  border-radius: 50%;
  top: 25%;
  left: -80px;
  z-index: 1;

  ${media.xl.min} {
    width: 300px;
    height: 300px;
    left: -160px;
    top: auto;
    bottom: 20%;

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 140px;
      height: 140px;
      background-color: ${({ theme }) => theme.colors.green};
      border-radius: 50%;
      bottom: -70px;
      left: 90px;
    }
  }
`

const BlueCircle = styled.div`
  position: absolute;
  height: 420px;
  width: 420px;
  border: 1px solid ${({ theme }) => theme.colors.blue};
  border-radius: 50%;
  top: -100px;
  right: -273px;
  z-index: 1;

  ${media.xl.min} {
    width: 1000px;
    height: 1000px;
    right: -200px;
    top: -500px;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 140px;
    height: 140px;
    background-color: ${({ theme }) => theme.colors.blue};
    border-radius: 50%;
    bottom: 0%;
    right: 130px;
  }
`

const OrangeCircle = styled.div`
  position: absolute;
  height: 360px;
  width: 360px;
  border: 1px solid ${({ theme }) => theme.colors.orange};
  border-radius: 50%;
  top: -80px;
  left: -175px;
  z-index: 1;

  ${media.xl.min} {
    width: 790px;
    height: 790px;
    left: 200px;
    top: -125px;
  }
`

const PolitykaPrywatnosci: React.FC<
  PageProps<PolitykaPrywatnosciPageQuery>
> = ({ data }) => {
  return (
    <Layout>
      <Seo
        title={data?.wpPage?.seo?.title!}
        description={data?.wpPage?.seo?.metaDesc!}
        ogTitle={data?.wpPage?.seo?.opengraphTitle!}
        ogDescription={data?.wpPage?.seo?.opengraphDescription!}
        ogImage={data?.wpPage?.seo?.opengraphImage?.sourceUrl!}
      />
      <StyledWrapper>
        <StyledCircles>
          <RedCircle />
          <GreenCircle />
          <BlueCircle />
          <OrangeCircle />
        </StyledCircles>
        <StyledHeader>
          <StyledTitle>Polityka Prywatności</StyledTitle>
        </StyledHeader>
        <StyledContentWrapper
          dangerouslySetInnerHTML={{ __html: data?.wpPage?.content! }}
        />
      </StyledWrapper>
    </Layout>
  )
}

export default PolitykaPrywatnosci

export const query = graphql`
  query PolitykaPrywatnosciPage {
    wpPage(slug: { regex: "/polityka-prywatnosci/" }) {
      id
      content
      seo {
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
      }
    }
  }
`
